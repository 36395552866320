'use client';

import { useEffect, useState } from 'react';
import cookie from 'js-cookie';

import Button from '@/components/Button';
import CopyToClipboard from '@/components/CopyToClipboard';
import { COOKIE_DEVICE_ID } from '@/constants';
import { resetCart } from '@/stores/Cart/CartStore';
import { setOrder } from '@/stores/Order/OrderStore';

import styles from './styles.module.scss';

const WALGREENS_DEFAULT_STORE_ID = 'WALGREENS_4627';

function DevUtils() {
  const [deviceId, setDeviceId] = useState<string>('No Device ID');

  useEffect(() => {
    setDeviceId(cookie.get(COOKIE_DEVICE_ID) || 'No Device ID');
  }, []);

  const setUserInfo = () => {
    setOrder((draft) => {
      draft.user = {
        lastName: 'Testing',
        firstName: 'Pictarine',
        email: 'pictarine.app.testing@gmail.com',
        phoneNumber: '(541) 555-5555',
      };
    });
  };

  const setStoreGlobal = () => {
    setOrder((draft) => {
      draft.storeId = WALGREENS_DEFAULT_STORE_ID;
    });
  };

  return (
    <article>
      <div className={styles.deviceId}>
        <code>Device Id: {deviceId}</code>
        <CopyToClipboard text={JSON.stringify(deviceId)} />
      </div>
      <div className={styles.wrapper}>
        <Button type="button" callback={resetCart} theme="secondary">
          Clear cart
        </Button>
        <Button
          type="button"
          callback={() => {
            setUserInfo();
            setStoreGlobal();
          }}
          theme="secondary"
        >
          Set user info & store
        </Button>
      </div>
    </article>
  );
}

export default DevUtils;
